const newseLatterData = [
	{
		id: '1',
		icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255) ', svg: 'icon-wallet' },

		title: ['Connect ','Radix ', 'Wallet'],
		text: "",
	},
	{
		id: '2',
		icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: 'icon-wallet' },

		title: ['Buy ', 'Some ', 'Tickets'],
		text: '',
	},
	{
		id: '3',
		icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: 'icon-gallery' },
		title: ['Reveal ', 'Your ', 'Numbers'],
		text: '',
	},
	{
		id: '4',
		icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: 'icon-list' },
		title: ['Collect ', 'Your ', 'Loot',],
		text: '',
	},
];

export { newseLatterData };
