import Image from "next/image";

const Partners = () => {
// TODO: add partners: UniSci, UniX, 
  const partnerGroup = [
    { id: 1, link: "https://radorables.com/", logo: "https://radorables.com/assets/img/logo_txt.png" },
    { id: 2, link: "https://www.thegnomesociety.com/home", logo: "/images/gnomes-logo.jpg" },
    { id: 3, link: "https://www.caviarnine.com/", logo: "/images/caviar-logo.png" },
    { id: 4, link: "https://radish.world/", logo: "/images/radish-logo.png" },
    { id: 5, link: "https://xrd.domains/", logo: "/images/rnsLogo.png" },
    { id: 6, link: "https://radixcharts.com/", logo: "/images/radixcharts-logo.png" },
    { id: 7, link: "https://t.me/unixsocial", logo: "/images/uniXLogo.jpg" },
    { id: 8, link: "https://t.me/uniscinft", logo: "/images/uniSciLogo.png" },
  ];
  return (
    <>
      <div className="dark:bg-jacarta-900 bg-gradient-to-r from-jacarta-100/10 to-jacarta-300/20 dark:to-accent-dark/10 pt-4 ">
        {/* title */}
        <h3 className="text-center font-display text-2xl font-bold text-jacarta-700 dark:text-white my-4">
          Our Partners
        </h3>
        <div className="container w-full flex justify-center ">
          <div className="grid grid-cols-2 py-8 sm:grid-cols-3 lg:grid-cols-3 justify-center items-center">
            {partnerGroup.map((item) => (
              <a
                href={item.link}
                target="_blank"
                className="mx-16 flex items-center justify-center rounded-md overflow-hidden"
                rel="noopener noreferrer"
                key={item.id}
              >
                <Image
                  width={173}
                  height={103}
                  className={`"object-contain ${item.id == 3 || item.id == 5 ? "bg-jacarta-500  border-4" : ""}"`}
                  src={`${item.logo}`}
                  alt="partner logo"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
