import Bids from "./bids/bids.jsx";
import HeadLine from "./headLine.jsx";
import NewseLatter from "./blog/newseLatter.jsx";
import Partners from "./partners/Partners.jsx";
import ItemsTabs from "./tabs/Tabs.jsx";

export {
  Bids,
  HeadLine,
  NewseLatter,
  Partners,
  ItemsTabs,
};
